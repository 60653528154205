import { debounce } from 'lodash';
import { navigate } from 'gatsby';
import React, { createContext, memo, useContext, useState } from 'react';
import ShortUniqueId from 'short-unique-id';
import { showNotification, updateNotification } from '@mantine/notifications';
//import firebase from 'gatsby-plugin-firebase';
import UserContext from './UserContext';
import { Check } from 'tabler-icons-react';
import { getUnsplashPhoto } from '../utils';
import initialState from '../data/initialState.json';

const DEBOUNCE_WAIT_TIME = 6000;

const resumeMetadata =  {
  "colors": {
    "background": "#FFFFFF",
    "primary": "#009688",
    "text": "#212121"
    },
  "font": "Open Sans",
  "fontSize": "9",
  "language": "en",
  "layout": {
    "onyx": [
      [
            "objective",
    "work",
    "education",
    "projects",
    "character",
    "strategic"
        ],
[
  "hobbies",
  "languages",
  "awards",
  "certifications",
  "communication",
  "analytical"
],
  [
    "skills",
    "references",
    "knowledge",
    "boardExperience"
  ]
    ],
"pikachu": [
  [
    "skills",
    "languages",
    "hobbies",
    "awards",
    "certifications",
    "character",
    "strategic"
  ],
  [
    "work",
    "education",
    "projects",
    "references",
    "knowledge",
    "communication",
    "analytical",
    "boardExperience"
  ]
],
  "gengar": [
    [
      "objective",
      "skills",
      "character",
      "strategic"
    ],
    [
      "awards",
      "certifications",
      "languages",
      "references",
      "hobbies",
      "communication",
      "analytical"
    ],
    [
      "work",
      "education",
      "projects",
      "knowledge",
      "boardExperience"
    ]
  ],
    "castform": [
      [
        "awards",
        "certifications",
        "languages",
        "hobbies",
        "communication",
        "analytical",
        "boardExperience"
      ],
      [
        "objective",
        "work",
        "education",
        "skills",
        "projects",
        "references",
        "character",
        "strategic",
        "knowledge"
      ]
    ],
      "glalie": [
        [
          "awards",
          "certifications",
          "hobbies",
          "languages",
          "boardExperience",
          "knowledge",
          "communication",
          "analytical",
          "character",
          "strategic"
        ],
        [
          "objective",
          "work",
          "education",
          "skills",
          "projects",
          "references",
        ]
      ],
        "celebi": [
          [
            "awards",
            "certifications",
            "languages",
            "hobbies",
            "communication",
            "analytical",
            "boardExperience"
          ],
          [
            "objective",
            "work",
            "education",
            "skills",
            "projects",
            "references",
            "character",
            "strategic",
            "knowledge"
          ]
        ]
    },
  "template": "glalie"
};

const defaultState = {
  isUpdating: false,
  createResume: async () => {},
  duplicateResume: async () => {},
  deleteResume: () => {},
  getResume: async () => {},
  getResumes: async () => {},
  viewResume: async () => {},
  updateResume: async () => {},
  debouncedUpdateResume: async () => {},
};

const DatabaseContext = createContext(defaultState);

/*
const wfetch = async (url, options) => {
  const token = localStorage.getItem('but');
  if(typeof token !== 'undefined' && token !== null) {
    return fetch(url, { ...options, credentials: 'include', headers: { Authorization: `Bearer ${token}` }})
  } else {
    if(/\/[r]\/{1}[\w]{6}\/$/.test(window.location.href) === false) {
      navigate('/login');
    } else  {
      return fetch(url, { ...options, credentials: 'include'});
    }
  }
}
*/

const wfetch = async (url, options) => {
  return fetch(url, { ...options, credentials: 'include' });
}

const DatabaseProvider = ({ children }) => {
  const dictionary = 'abcdefghijklmnopqrstuvwxyz1234567890'.split('');
  const uuid = new ShortUniqueId({ dictionary });

  const [isUpdating, setUpdating] = useState(false);
  const { user, setResumes, resumes, getUsersBoardDocuments } = useContext(UserContext);

  var endpoint = "";

  if ( typeof window !== `undefined` ) {
    if(window.location.host == "localhost:8000") {
      endpoint = "http://localhost:8080";
    } else {
      endpoint = "https://api.v4.boardsi.com";
    }
  }

  const getResume = async (id) => {
    const data = await wfetch( endpoint+`/api/v3/board-document/get/${id}/`, { credentials: 'include' } );
    const result = await data.json();
    let results = {}

    if(result.length > 0) {
      results = result[0];
    }

    console.log(result);

    return results;
  };

  /*
  const viewResume = async (id) => {
    const data = await wfetch( endpoint+`/api/v3/board-document/view/${id}/` );
    const result = await data.json();
    let results = {}

    if(result.length > 0) {
      results = result[0];
    }

    console.log(result);

    return results;
  };
  */
 
  const viewResume = async (id) => {
    const data = await wfetch(endpoint + `/api/public-profile/${id}`);
    const result = await data.json();
    let results = {}

    if(typeof result == 'object') {
      results = {...result[0], "metadata": resumeMetadata}
    }

    console.log(result);

    return results;
  };

  const createResume = async ({ name }) => {
    var body = {name: name};

    const result = await wfetch( endpoint+"/api/v3/board-document/create/", { method: 'POST', credentials: 'include', body: JSON.stringify( body ) } )
    const data = await result.json();

    setResumes([]);
    await getUsersBoardDocuments();

    setTimeout(()=>{
      showNotification( {
        disallowClose: false,
        autoClose: 7000,
        title: "Board Document " + name,
        message: 'was successfully created!',
        color: 'green',
        loading: false,
      } );
    }, 1500);

  };

  const duplicateResume = async (originalResume) => {
    const resume = {
      ...originalResume,
      name: `${originalResume.name}`,
//      preview: preview,
    };

    const result = await wfetch( endpoint+"/api/v3/board-document/duplicate/", { method: 'POST', credentials: 'include', body: JSON.stringify( resume ) } )
    const data = await result.json();

    console.log(data);

    var newResumes = resumes;

    const {id, name, createdAt, updatedAt} = resume;
    newResumes.push({id, name, createdAt, updatedAt});
    setResumes(newResumes);

    setTimeout(()=>{
      updateNotification( {
        id: "boardDocDuplication",
        disallowClose: false,
        autoClose: 3000,
        title: "Board Document " + originalResume.name + " (copy)",
        message: 'was successfully created!',
        color: 'green',
        loading: false,
      } );
    }, 1500);
  };

  const updateResume = async (resume) => {
    setUpdating(true);

    const result = await wfetch( endpoint+"/api/v3/board-document/update/", { method: 'POST', credentials: 'include', body: JSON.stringify( resume ) } );
    const data = await result.json();

    setTimeout(()=>{
      setUpdating(false);   
    }, 1500);
  };

  const debouncedUpdateResume = debounce(updateResume, DEBOUNCE_WAIT_TIME);

  const deleteResume = async (id) => {
    //await firebase.database().ref(`/resumes/${id}`).remove();
  };

  return (
    <DatabaseContext.Provider
      value={{
        isUpdating,
        getResume,
        createResume,
        duplicateResume,
        updateResume,
        viewResume,
        deleteResume,
        debouncedUpdateResume,
      }}
    >
      {children}
    </DatabaseContext.Provider>
  );
};

export default DatabaseContext;

const memoizedProvider = memo(DatabaseProvider);

export {
  memoizedProvider as DatabaseProvider,
  DEBOUNCE_WAIT_TIME as DebounceWaitTime,
};
